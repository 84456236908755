<template>
  <a-card class="main-content">
    <template slot="title">
      <span class="table-title">设备列表</span>
    </template>
    <!-- 列表 -->
    <a-table
      :loading="loading"
      row-key="kid"
      :columns="columns"
      :data-source="datalist"
      :pagination="pagination"
      @change="onPageChange">
      <span slot="status" slot-scope="text, record">
        <a-tag v-if="text == 0" color="red">未绑定</a-tag>
        <a-tag v-else-if="text == 1" color="green">绑定</a-tag>
        <a-tag v-else color="blue">{{text}}</a-tag>
      </span>
      <span slot="action" slot-scope="record">
        <a-button 
          v-if="record.status != 1" 
          type="link" 
          @click="showModal(record)">绑定</a-button>
          <a-popconfirm
            title="您确认删除该设备吗？"
            @confirm="del(record.kid)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
      </span>
    </a-table>
    <!-- 激活 -->
    <a-modal
      title="绑定设备"
      :visible="dialog"
      :confirm-loading="confirmLoading"
      @ok="bind()"
      @cancel="dialog = false">
      <a-form-model
        ref="modalForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }">
        <a-form-model-item label="设备名称" prop="sno">
          <a-input v-model="form.sno" placeholder="设备名称"/>
        </a-form-model-item>
        <a-form-model-item label="选择公司" prop="coid">
          <a-select
            v-model="form.coid"
            option-label-prop="label"
            allow-clear
            placeholder="请选择要绑定的公司"
            @change="getProjectList">
            <a-select-option
              v-for="item in companies"
              :key="item.kid"
              :label="item.name">
              <div class="option">
                <span class="option-title">{{item.name}}</span>
                <div class="option-memo">{{item.remark}}</div>
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="form.coid" label="选择项目" prop="proj_id">
          <a-select
            v-model="form.proj_id"
            allow-clear
            placeholder="请选择要绑定的项目"
            @change="selectProject">
            <a-select-option
              v-for="item in projects"
              :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-show="false" prop="proj_name">
          <a-input v-model="form.proj_name"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="tags">
          <a-textarea v-model="form.tags" :autoSize="{ minRows: 3}" placeholder="备注"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      searchForm: {
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '序号', key: 'kid', dataIndex: 'kid', align: 'center', width: 65, customRender: (text, record, index) =>`${(this.pagination.current - 1) *this.pagination.pageSize + index + 1}`},
        { title: '设备名称', key: 'sno', dataIndex: 'sno', ellipsis: true},
        { title: '绑定公司', key: 'company', dataIndex: 'company', ellipsis: true },
        { title: '公司描述', key: 'remark', dataIndex: 'remark', ellipsis: true, align: 'center' },
        { title: '所属项目', key: 'proj_name', dataIndex: 'proj_name', ellipsis: true, align: 'center' },
        { title: '设备备注', key: 'tags', dataIndex: 'tags', ellipsis: true, align: 'center' },
        { title: '设备码', key: 'scode', dataIndex: 'scode', ellipsis: true, align: 'center' },
        { title: '类型', key: 'sort', dataIndex: 'sort', ellipsis: true, align: 'center' },
        { title: '状态', key: 'status', dataIndex: 'status', ellipsis: true, scopedSlots: { customRender: 'status'}, align: 'center' },
        { title: '操作', key: 'action', scopedSlots: { customRender: 'action'}, align: 'center' }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: total => `总共 ${total} 条`,
        pageSizeOptions: ['10', '20', '30', '50']
      },
      datalist: [],
      // 弹框
      dialog: false,
      confirmLoading: false,
      companies: [],
      projects: [],
      form: {
        sno: '',
        coid: undefined,
        proj_id: undefined,
        proj_name: '',
        kid: '',
        tags: ''
      },
      rules: {
        sno: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        coid: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }]
      }
    }
  },
  mounted () {
    this.getDeviceList()
  },
  methods: {
    getDeviceList () {
      this.loading = true 
      this.api.getDeviceList(this.searchForm).then(res => {
        this.loading = false
        this.datalist = res.data.data
        this.pagination.total = res.data.total
      }).catch(() => {
        this.loading = false
      })
    },
    onPageChange (pagination) {
      this.pagination = pagination
      this.searchForm.page = pagination.current
      this.searchForm.list_rows = pagination.pageSize
      this.getDeviceList()
    },
    showModal (record) {
      this.dialog = true;
      this.form.kid = record.kid;
      this.allCompanies()
      this.$nextTick(() => {
        this.$refs.modalForm.resetFields()
      })
    },
    bind () {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          this.api.bindDevice(this.form).then(res => {
            this.confirmLoading = false
            this.$message.success(res.data.msg)
            this.getDeviceList()
            this.dialog = false
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          return false
        }
      })
    },
    del (deviceId) {
      this.api.delDevice({kid: deviceId}).then(res => {
        this.$message.success(res.data.msg)
        this.getDeviceList()
      })
    },
    allCompanies () {
      this.api.allCompanies().then(res => {
        this.companies = res.data.data
      })
    },
    getProjectList (val) {
      this.form.proj_id = undefined
      let company = this.companies.find(item => item.kid == val)
      this.api.getCompanyProject({uuid: company.uuid}).then(res => {
        this.projects = res.data.data
      })
    },
    selectProject (val) {
      let pro = this.projects.find(item => item.id == val)
      this.form.proj_name = pro.project_name
    }
  }
}
</script>

<style lang="less" scoped>
.option {
  min-height: 50px;
  height: unset;
  border-bottom: 1px dashed #EBEEF5;
  white-space: pre-wrap;
}
.option-title {
  font-weight: bold;
}
.option-memo {
  font-size: 12px;
  line-height: 24px;
  color: #909399;
}
</style>